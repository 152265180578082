import axios from "axios";
import QS from "qs";

/**
 * @name: 封装通用请求（get、post）方法
 * @author: camellia
 * @date: 2021-12-21 
 * @param:	type	string	请求类型(post get)
 * @param:	url	string	请求连接
 * @param:	params	json	请求参数
 * @param:	callback	function	回调函数
 */
export function request(type, url, params, callback = null)
{
    if (type == 'POST') 
    {
        return new Promise((resolve, reject) => {
            axios
                .post(url, QS.stringify(params))
                .then((res) => {
                    callback ? resolve(callback(res.data)) : resolve(res.data);
                })
                .catch((err) => {
                    reject(err);
                });
        });
    }
    else
    {
        return new Promise((resolve, reject) => {
            axios
                .get(url, {
                    params: params,
                })
                .then((res) => {
                    callback ? resolve(callback(res.data)) : resolve(res.data);
                })
                .catch((err) => {
                    reject(err);
                });
        });
    }
}
/**
 * @name: 封装get方法
 * @author: camellia
 * @date: 2021-12-21 
 * @param:	url	string	请求连接
 * @param:	params	json	请求参数
 * @param:	callback	function	回调函数
 */
export function get(url, params, callback = null) {
    return new Promise((resolve, reject) => {
        axios
            .get(url, {
                params: params
            })
            .then(res => {
                callback ? resolve(callback(res.data)) : resolve(res.data);
            })
            .catch(err => {
                reject(err);
            });
    });
}

/**
 * @name: 封装post方法
 * @author: camellia
 * @date: 2021-12-21 
 * @param:	url	string	请求连接
 * @param:	params	json	请求参数
 * @param:	callback	function	回调函数
 */
export function post(url, params, callback = null) {
    return new Promise((resolve, reject) => {
        axios
            .post(url, QS.stringify(params))
            .then(res => {
                callback ? resolve(callback(res.data)) : resolve(res.data);
            })
            .catch(err => {
                reject(err);
            });
    });
}
/**
 * @name: 封装上传文件方法
 * @author: camellia
 * @date: 2021-12-21 
 * @param:	url	string	请求连接
 * @param:	params	json	请求参数
 * @param:	file	object	文件流
 * @param:	fileName	string	文件名称
 * @param:	callback	function	回调函数
 */
export function upload(url, params, file, fileName = null, callback = null) {
    let formData = new FormData();
    if (fileName) {
        formData.append("file", file, fileName);
    } else {
        formData.append("file", file);
    }

    let uri = "",
        item;
    for (item in params) {
        if (params[item] && String(params[item])) {
            uri += uri === "" ? "?" : "&";
            uri += `${item}=${decodeURI(params[item])}`;
        }
    }

    return new Promise((resolve, reject) => {
        axios
            .post(url + uri, formData, {
                headers: { "Content-Type": "multipart/form-data" }
            })
            .then(res => {
                callback ? resolve(callback(res.data)) : resolve(res.data);
            })
            .catch(err => {
                reject(err);
            });
    });
}

/**
 * @name: 本地用的假数据方法
 * @author: camellia
 * @date: 2021-12-21 
 */
export function local(data, timeout = 0) {
    return new Promise(resolve => {
        setTimeout(() => {
            resolve({
                code: 1,
                data: data
            });
        }, timeout);
    });
}
